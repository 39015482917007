import React from "react"
import {normalizePath} from "../../utils/get-url-path";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import sanitizeHtml from "sanitize-html";
import sanitizeOptions from "../../helper/sanitizeOptions";

const DropdownMenu = ({ submenus, dropdown, closeDropdown }) => {
    const isPartiallyActive = ({isPartiallyCurrent}) => {
        return isPartiallyCurrent
            ? {className: "active-menu-item"}
            : {}
    };
    const sanitizeOpt = sanitizeOptions();

    return (
        <ul className={`dropdown-menu dropdown ${dropdown ? "show" : ""}`}>
              <span className={'dropdown-btn close'} aria-expanded={dropdown ? "true" : "false"}
                    onClick={closeDropdown}
              ></span>
            {submenus.map((submenu, index) => (
                <li key={index} className={`dropdown-menu-item index_${index}`}>
                    <AniLink paintDrip
                             duration={1}
                             hex="#ed5d2b"
                             getProps={isPartiallyActive}
                             to={normalizePath(submenu.url)}
                    >
                        <span dangerouslySetInnerHTML={{__html: sanitizeHtml(submenu.label, sanitizeOpt.title)}}></span>
                    </AniLink>
                </li>
            ))}
        </ul>
    );
};

export default DropdownMenu;