//v2
// export const GOOGLE_SITE_KEY = '6Ld9fQoaAAAAAIaN1GFycR8jdwQs9J1xmCzQxUsC';
//v3
export const GOOGLE_SITE_KEY = '6Ld15DIaAAAAAJnn9QzAwMhqlAEG4infTcu8y2w7';
export const SITE_BASE_URL = process.env.WP_BASE_URL;
export const MOBILE_WIDTH = 768;
export const DESKTOP_WIDTH = 1024;
export const SM_MOBILE_WIDTH = 640;
export const DEFAULT_SUCCESS_URL_PAGE = 'thankyou';
export const CAREERS_SUCCESS_URL_PAGE = 'thank_you';
export const CAREERS_CONTACT_US_CLASS_WRAPPER = 'careers-contacts';
export const EVENTS_CONTACT_US_CLASS_WRAPPER = 'events-contacts';
export const CONTACT_US_ID_CAREERS = 5481;
export const CONTACT_US_ID_DEFAULT = 1831;
//Dev
// export const CONTACT_US_ID_SPEAKER = 18581;
// export const CONTACT_US_ID_PARTICIPIANT = 18584;
//Prod
export const CONTACT_US_ID_SPEAKER = 21505;
export const CONTACT_US_ID_PARTICIPIANT = 21510;
export const BLOG_SEARCH_MAX_ITEMS = 8;