
// Initialization of the Google captcha

export default function addGoogleCaptchaScript() {
    const captchaId = 'data-captcha';
    let scriptCaptcha = document.getElementById(captchaId);

    if (!!scriptCaptcha) {
        return false;
    }

    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
    s.id = captchaId;
    document.body.appendChild(s);
    return true;
}