import React, {useEffect} from "react";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import sanitizeAddCustomLink from "../../helper/sanitizeAddCustomLink";

const ContactBlockDescription = ({data}) => {
    const sanitizeOpt = sanitizeOptions();
    const {contactBlock} = data;

    useEffect(() => {
        sanitizeAddCustomLink();
    }, []);

    return (
        <div className={'contact-block white'}>
            {!!contactBlock?.description && (
                <div className={'description'}
                     dangerouslySetInnerHTML={{__html: sanitizeHtml(contactBlock.description, sanitizeOpt.title)}}/>
            )}
            {!!contactBlock?.text && (
                <div className={'text'}
                     dangerouslySetInnerHTML={{__html: sanitizeHtml(contactBlock.text, sanitizeOpt.contentData)}}/>
            )}
        </div>
    )
}

export default ContactBlockDescription;
