import * as constants from "./constants";

export default function getFormUrl({urlPath, urlValue, typeForm}) {
    let setUrl;
    let getUrl;
    switch (typeForm) {
        case constants.CAREERS_CONTACT_US_CLASS_WRAPPER:
            setUrl = constants.CAREERS_SUCCESS_URL_PAGE;
            break;
        default:
            setUrl = constants.DEFAULT_SUCCESS_URL_PAGE;
    }
    getUrl = `${urlPath.origin}/${!!urlValue ? urlValue : setUrl}/`;
    urlPath.replace(getUrl);
};