import {gql} from "@apollo/client";

export const subscribeData = gql`
    query MyQuery {
        form(id: 12440) {
            content
            id
            name
        }
    }
`;