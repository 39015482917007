import React, {useState, useEffect} from 'react';
import NavMenu from "../template-parts/menu";
import * as constants from "../../helper/constants";

const createMobileLinks = (items) => {
    const data = {
        menuItems: {
            nodes: [...items]
        }
    };
    return data;
};

const MobileMenu = () => {
    const items = [
        {
            label: 'Home',
            url: '/'
        },
        {
            label: 'Portfolio',
            url: '/portfolio/'
        },
        {
            label: 'Careers',
            url: '/careers/'
        },
        {
            label: 'Contacts us',
            url: '/contacts/'
        },
    ];
    const wpMenuMobile = createMobileLinks(items);
    const [windowSize, setWindowSize] = useState(false);

    useEffect(() => {
        setWindowSize(window.innerWidth < constants.MOBILE_WIDTH);
    }, []);

    return (
        <>
            {windowSize && (<nav className={'wrapper__mob-menu-wrapper'}>
                    <NavMenu data={{wpMenuMobile}}
                             disableAnimation={true}/>
                </nav>
            )}
        </>
    )
}

export default MobileMenu