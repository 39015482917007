import React, { useState } from "react";
import { normalizePath } from "../../utils/get-url-path";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import * as constants from "../../helper/constants";
import Preloader from "./Preloader";
import Recaptcha from "react-recaptcha";
import TrackVisibility from 'react-on-screen';

const SpeakerParticipiantForm = ({
                                     loader,
                                     isFile,
                                     showCaptcha,
                                     formData,
                                     validateFile,
                                     makeId,
                                     callback,
                                     executeCaptcha,
                                     onSubmit,
                                     setIsSpeaker,
                                     getRecaptchaInstance,
                                     upcomingEventsState
                                 }) => {
    const [isChecked, setIsChecked] = useState(false);
    const {
        register,
        handleSubmit,
        errors,
        formState,
        formId,
        emailData,
        parentBlockContactForm,
        typeBlockContactForm,
        isSpeaker
    } = formData;
    if (!upcomingEventsState) {
        setIsSpeaker(true);
    }
    return (
        <div className={`speaker-participant`}>
            <div className={`form-toolbar`}>
                {upcomingEventsState ? <><button
                    onClick={() => setIsSpeaker(false)}
                    className={`button participant white ${!isSpeaker ? 'active' : ''}`}>
                    Become a participant
                </button>
                <button
                    onClick={() => setIsSpeaker(true)}
                    className={`button speaker white ${isSpeaker ? 'active' : ''}`}>
                    Become a speaker
                </button></> :
                    <span
                        className={`button without-upcoming-event white active`}>
                        Become a speaker
                    </span>
                }
            </div>
            <form
                // onSubmit={handleSubmit(onSubmit)}
                id={formId}
                className={'form contact-form'}>
                <div className={`input-wrapper${(errors.your_name ? ' invalid' : '')}`}>
                    <input
                        ref={register({
                            required: {
                                value: false,
                            },
                        })}
                        name={'formId'}
                        type={'hidden'}
                        value={formId}
                    />
                    <input
                        name={'your_name'}
                        className={`input white full-width`}
                        type="text"
                        placeholder={'Name*'}
                        ref={register({
                            required: {
                                value: true,
                                message: 'Please enter your name.'
                            },
                            minLength: {
                                value: 2,
                                message: 'Minimum name length is 2.'
                            },
                            maxLength: {
                                value: 40,
                                message: 'Maximum name length is 40.'
                            },
                            pattern: {
                                value: /^[a-zA-Zа-яА-ЯёЁ\-ÀàáäÂâÆæßÇçÈèÉéÊêËëÎîÏïíJÑñÔôÖöóŒœÙùúÛûÜü _]+$/i,
                                message: 'Allowed only: a-zA-Z'
                            }

                        })}
                    />
                    {errors.your_name?.message &&
                    <div className={'error-badge'}>
                        <i className={'mc-icon mc-icon--caution'}/>
                    </div>}
                    {errors.your_name?.message &&
                    <div className={'error-message'}>
                        {errors.your_name?.message}
                    </div>}
                </div>

                <div className={`input-wrapper${(errors.your_email ? ' invalid' : '')}`}>
                    <input
                        ref={register({
                            required: {
                                value: true,
                                message: 'Please specify a valid email address.'
                            },
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please provide correct email.'
                            },
                        })}
                        name={'your_email'}
                        className={`input white full-width`}
                        type="email"
                        placeholder={'Email*'}
                    />
                    {errors.your_email?.message &&
                    <div className={'error-badge'}>
                        <i className={'mc-icon mc-icon--caution'}/>
                    </div>}
                    {errors.your_email?.message &&
                    <div className={'error-message'}>
                        {errors.your_email?.message}
                    </div>}
                </div>

                {!isSpeaker && <div className={`input-wrapper${(errors.your_phone ? ' invalid' : '')}`}>
                    <input
                        ref={register({
                            required: {
                                value: true,
                                message: 'Please specify a valid email address.'
                            },
                            pattern: {
                                value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                                message: 'Please provide correct phone number.'
                            },
                        })}
                        name={'your_phone'}
                        className={`input white full-width`}
                        type="tel"
                        placeholder={'Phone*'}
                    />
                    {errors.your_phone?.message &&
                    <div className={'error-badge'}>
                        <i className={'mc-icon mc-icon--caution'}/>
                    </div>}
                    {errors.your_phone?.message &&
                    <div className={'error-message'}>
                        {errors.your_phone?.message}
                    </div>}
                </div>}

                <div className={`input-wrapper${(errors.your_company ? ' invalid' : '')}`}>
                    <input
                        name={'your_company'}
                        className={`input white full-width`}
                        type="text"
                        placeholder={'Company*'}
                        ref={register({
                            required: {
                                value: true,
                                message: 'Please enter your company name.'
                            }
                        })}
                    />
                    {errors.your_company?.message &&
                    <div className={'error-badge'}>
                        <i className={'mc-icon mc-icon--caution'}/>
                    </div>}
                    {errors.your_company?.message &&
                    <div className={'error-message'}>
                        {errors.your_company?.message}
                    </div>}
                </div>

                {isSpeaker && <div className={`input-wrapper${(errors.your_position ? ' invalid' : '')}`}>
                    <input
                        name={'your_position'}
                        className={`input white full-width`}
                        type="text"
                        placeholder={'Position*'}
                        ref={register({
                            required: {
                                value: true,
                                message: 'Please enter your position.'
                            }
                        })}
                    />
                    {errors.your_position?.message &&
                    <div className={'error-badge'}>
                        <i className={'mc-icon mc-icon--caution'}/>
                    </div>}
                    {errors.your_position?.message &&
                    <div className={'error-message'}>
                        {errors.your_position?.message}
                    </div>}
                </div>}

                {isSpeaker && <div className={`input-wrapper${(errors.your_topic ? ' invalid' : '')}`}>
                    <input
                        name={'your_topic'}
                        className={`input white full-width`}
                        type="text"
                        placeholder={'Topic*'}
                        ref={register({
                            required: {
                                value: true,
                                message: 'Please enter your topic.'
                            }
                        })}
                    />
                    {errors.your_topic?.message &&
                    <div className={'error-badge'}>
                        <i className={'mc-icon mc-icon--caution'}/>
                    </div>}
                    {errors.your_topic?.message &&
                    <div className={'error-message'}>
                        {errors.your_topic?.message}
                    </div>}
                </div>}

                {isSpeaker &&
                <div className={`textarea-wrapper input-wrapper${(errors.your_message ? ' invalid' : '')}`}>
                    <textarea
                        ref={register({
                            required: {
                                value: false
                            },
                        })}
                        name={'your_message'}
                        className={`input white full-width`}
                        placeholder={'Message'}
                        rows='3'
                    />
                    {errors.your_message?.message &&
                    <div className={'error-badge'}>
                        <i className={'mc-icon mc-icon--caution'}/>
                    </div>}
                    {errors.your_message?.message &&
                    <div className={'error-message'}>
                        {errors.your_message?.message}
                    </div>}
                </div>}

                {isSpeaker && <>
                    <div className={'upload-btn-wrapper'}
                         id={'file-wrapper'}>
                        <button className={'btn white'}><span>Attach</span> (.pdf .docx .png, .zip, .rar or .txt)
                        </button>
                        <input
                            accept=".pdf,.docx,.png,.zip,.rar,.txt"
                            type={'file'}
                            name={'file'}
                            ref={register({
                                validate: (value) => validateFile(value)
                            })}
                            onChange={validateFile}
                        />
                        {errors.file?.message &&
                        <div className={'error-message'}>
                            {errors.file.message}
                        </div>}
                    </div>
                    {!!isFile && !!isFile.isType && (
                        <div className={'file success-message'}>
                            {`The ${isFile.name} file is attached.`}
                        </div>
                    )}
                    {!!isFile && !!isFile.errorMessage && (
                        <div className={'error-message-text'}>
                            {isFile.errorMessage}
                        </div>
                    )}
                </>}

                <input
                    ref={register({
                        required: {
                            value: true,
                            message: 'Please confirm that you agree to our Privacy Policy and Terms of use.'
                        },
                    })}
                    name={'acceptPolicy'}
                    className={'input white'}
                    type={'checkbox'}
                    id={`accept-policy-${parentBlockContactForm}-${formId}`}
                    checked={isChecked}
                    onChange={() => {
                        setIsChecked(!isChecked);
                    }}
                />

                <label
                    htmlFor={`accept-policy-${parentBlockContactForm}-${formId}`}
                    className={`accept-policy ${!!errors?.acceptPolicy ? 'error-message-label' : ''}`}
                >
                    {errors.acceptPolicy?.message &&
                    <span className={'error-message-text'}>
                        {errors.acceptPolicy.message}
                    </span>}
                    <span>By clicking this button, you accept <AniLink paintDrip duration={1} hex="#ed5d2b"
                                                                       to={normalizePath('terms-of-service')}>Terms of Service</AniLink> and <AniLink
                        paintDrip duration={1} hex="#ed5d2b"
                        to={normalizePath('privacy-policy')}>Privacy Policy</AniLink></span>
                </label>
                <button
                    id={`${formId}-${typeBlockContactForm}-${parentBlockContactForm}`}
                    type={'submit'}
                    className={'mc-button-secondary transition-link white'}
                    disabled={formState.isSubmitting}
                    onClick={executeCaptcha}
                >Send
                </button>
                <TrackVisibility once offset={900}>
                    {({ isVisible }) => isVisible && showCaptcha &&
                        <Recaptcha
                            ref={getRecaptchaInstance}
                            sitekey={constants.GOOGLE_SITE_KEY}
                            size="invisible"
                            verifyCallback={handleSubmit(onSubmit)}
                            onloadCallback={callback}
                            elementID={`g-recaptcha-${makeId(formId)}`}
                        />}
                </TrackVisibility>
                <Preloader
                    status={emailData?.submitForm?.status}
                    loader={loader}
                    message={emailData?.submitForm?.message}
                />
            </form>
        </div>
    );
}

export default SpeakerParticipiantForm;