
//when we are copying content it script is adding a link with the current page.

export default function protectCopyContent(){
    let chooseEl = window.getSelection();
    let choose = chooseEl.toString();
    if(choose.trim() === 'magecom' || choose.trim() === 'magecom.net' ) {
        return choose;
    }
    const istS = 'Source:';
    const copyR = '© Magecom.net';
    const body_element = document.getElementsByTagName('body')[0];
    let myLink = document.location.href;
    let copytext = `${choose} <br/>${istS} <a href=${myLink}>${myLink}</a><br/>${copyR}`;
    let addDiv = document.createElement('div');
    addDiv.style.position='absolute';
    addDiv.style.left='-99999px';
    body_element.appendChild(addDiv);
    addDiv.innerHTML = copytext;
    chooseEl.selectAllChildren(addDiv);
    window.setTimeout(function() {
        body_element.removeChild(addDiv);
    },0);
}
