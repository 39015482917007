import React from "react";
import ContactForm from "../contact-form";

const ContactBlockForm = ({captchaRender, data, urlValue}) => {
    const {contactBlock, hideContactForm, parentBlock} = data;
    return (
        <>
            {!!contactBlock?.titleH2 && (
                <div className={'form-block'}>
                    {!!contactBlock?.titleH4 && (
                        <h4 className={'h4-title white'}>{contactBlock.titleH4}</h4>
                    )}
                    <h2 className={'h2-title white'}>
                        {contactBlock.titleH2}
                    </h2>
                    {!hideContactForm && (
                        <ContactForm
                            captchaRender={captchaRender}
                            parentBlock={parentBlock}
                            typeForm={contactBlock?.classWrapper}
                            urlValue={urlValue}
                            upcomingEventsState={contactBlock?.upcomingEventsState}
                        />
                    )}
                </div>
            )}
        </>
    )
}


export default ContactBlockForm;