
//To add the skype & tel url for the link in the admin content with sanitizeHtml
//The link address should be with data-href attribute & class="replace-href" in admin content

export default function sanitizeAddCustomLink(){
    const replaceHrefElements = document.getElementsByClassName('replace-href');

    Array.from(replaceHrefElements).forEach(function (item) {
        const href =  item.getAttribute("data-href");

        item.setAttribute('href', href);
    });
}
