import * as constants from "./constants";

export default function queriesForm(typeForm) {
    let queriesFormsData = {};
    switch (typeForm) {
        case constants.CAREERS_CONTACT_US_CLASS_WRAPPER:
            queriesFormsData['idForm'] = constants.CONTACT_US_ID_CAREERS;
            break;
        case constants.EVENTS_CONTACT_US_CLASS_WRAPPER:
            let eventsIds = [];
            eventsIds.push(constants.CONTACT_US_ID_SPEAKER, constants.CONTACT_US_ID_PARTICIPIANT);
            queriesFormsData['idForm'] = eventsIds;
            break;
        default:
            queriesFormsData['idForm'] = constants.CONTACT_US_ID_DEFAULT;
    }
    return queriesFormsData;
}
