import React, {useEffect, useState, useRef} from "react"
import {normalizePath} from "../../utils/get-url-path"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import DropdownMenu from './Dropdown-menu'
import {globalHistory as history} from "@reach/router";

export const MenuItem = ({menuItem, index, disableAnimation, menuExpanded}) => {
    const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url;
    const [showMenuItem, setShowMenuItem] = useState(false);
    const delay = typeof window !== 'undefined' ? window.location.pathname === '/' ? (500 + index * 50) : 0 : 0;
    const stylesItemsOpacity = {
        overflow: 'initial'
    };
    const styleAnimationsItems = {
        display: 'block',
        opacity: showMenuItem ? 1 : 0,
        transform: `translateY(${showMenuItem ? '0' : '100%'})`,
        transition: 'all 0.5s ease'
    };
    const styleWhithOutAnimations = {
        display: 'inherit'
    };
    const absoluteLinksPattern = new RegExp('^(ftp|http|https):\\/\\/[^ "]+$');
    let t = new RegExp(/[/]/g);
    let classFilter = menuItem.url.replace(t, '');
    const {location} = history;

    const submenuItems = menuItem?.childItems?.nodes;

    const isActiveState = () => {
        let isActive = false
        if(submenuItems?.length) {
            isActive = submenuItems.some(submenu => submenu.url === location.pathname)
        }
        return isActive;
    };

    const isPartiallyActive = ({
          isPartiallyCurrent
        }) => {
        return isPartiallyCurrent
            ? {className: "active-menu-item"}
            : {}
    };
    let ref = useRef();
    const [dropdown, setDropdown] = useState(false);

    const onMouseEnter = () => {
        (window.innerWidth > 960 && menuExpanded === undefined) && setDropdown(true);
    };

    const onMouseLeave = () => {
        (window.innerWidth > 960 && menuExpanded === undefined) && setDropdown(false);
    };

    useEffect(() => {
        if (!showMenuItem && !disableAnimation) {
            window.setTimeout(() => {
                setShowMenuItem(true)
            }, delay)
        }

        const handler = (event) => {
            if (dropdown && menuExpanded === undefined && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [delay, disableAnimation, showMenuItem, dropdown]);

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    const toggleDropdown = (e) => {
        e.preventDefault()
        setDropdown((prev) => !prev)
    }

    return (
        <li style={!disableAnimation ? stylesItemsOpacity : styleWhithOutAnimations}
            className={`menu-items parent-item link-${classFilter ? classFilter : 'home'} ${dropdown ? 'dropdown-active' : ''}`}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {!absoluteLinksPattern.test(path)
                ? <AniLink paintDrip
                           duration={1}
                           hex="#ed5d2b"
                           key={`${index}_${menuItem.url}`}
                           style={!disableAnimation ? styleAnimationsItems : styleWhithOutAnimations}
                           getProps={isPartiallyActive}
                           to={normalizePath(path)}
                           className={`${isActiveState() ? 'active-menu-item' : ''}`}
                >
                    <span>{menuItem.label}</span>
                    {(!!menuItem?.childItems?.nodes.length) && (
                        <span className={'dropdown-btn'} aria-expanded={dropdown ? "true" : "false"}
                              onClick={toggleDropdown}
                        ></span>
                    )}
                </AniLink>
                : <a target='_blank'
                     rel='noreferrer'
                     className={classFilter}
                     href={path}>{menuItem.label}
                </a>
            }
            {(!!menuItem?.childItems?.nodes.length) && (
                <DropdownMenu
                    dropdown={dropdown}
                    submenus={menuItem?.childItems?.nodes}
                    closeDropdown={closeDropdown}
                />
            )}
        </li>
    )
}
