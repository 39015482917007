import React from "react";

import "../style.scss"
import ContactBlockDescription from "./description";
import ContactBlockForm from "./form";

const ContactBlock = ({captchaRender, contactBlock, hideContactForm, parentBlock, urlValue}) => {

    return (
        <>
            <ContactBlockForm
                captchaRender={captchaRender}
                data={{contactBlock, hideContactForm, parentBlock}}
                urlValue={urlValue}/>
            <ContactBlockDescription data={{contactBlock}}/>
        </>
    );
};

export default ContactBlock;