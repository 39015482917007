import {gql} from "@apollo/client";

export const sendEmail = gql`
    mutation SEND_EMAIL($form_data: String!, $formId:Int!) {
        submitForm(
	        input: {
		        clientMutationId: ""
	            form_data: $form_data
	            id: $formId
	        }
        )
        {
            message
            status
        }
    }
`;
