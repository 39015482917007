import {gql} from "@apollo/client";

export const contactUsData = gql`
    query MyQuery ($id: Int!) {
        form(id: $id) {
            content
            id
            name
        }
    }
`;