import sanitizeHtml from 'sanitize-html';

export default function sanitizeOptions() {
    const additionalAttributes = ['class', 'id', 'data-*', 'aria-*', 'style'];
    const sanitizeOptions = {
        title: {
            allowedAttributes: {
                a: additionalAttributes.concat(['href', 'target', 'name', 'rel']),
                img: additionalAttributes.concat(['src']),
                p: additionalAttributes,
                div: additionalAttributes,
                h1: additionalAttributes,
                h2: additionalAttributes,
                h3: additionalAttributes,
                h4: additionalAttributes,
                h5: additionalAttributes,
                h6: additionalAttributes,
                span: additionalAttributes,
                ul: additionalAttributes,
                ol: additionalAttributes,
                li: additionalAttributes.concat(['value']),
                picture: additionalAttributes,
                source: additionalAttributes.concat(['src']),
            }
        },
        contentData: {
            allowedTags:sanitizeHtml.defaults.allowedTags.concat([ 'img', 'video', 'section', 'picture', 'p' ]),
            allowedAttributes: {
                a: additionalAttributes.concat(['href', 'target', 'name', 'rel']),
                img: additionalAttributes.concat(['src']),
                p: additionalAttributes,
                div: additionalAttributes,
                h1: additionalAttributes,
                h2: additionalAttributes,
                h3: additionalAttributes,
                h4: additionalAttributes,
                h5: additionalAttributes,
                h6: additionalAttributes,
                span: additionalAttributes,
                ul: additionalAttributes,
                ol: additionalAttributes,
                li: additionalAttributes.concat(['value']),
                picture: additionalAttributes,
                source: additionalAttributes.concat(['src']),
                video: additionalAttributes.concat(['src', 'type', 'controls', 'poster', 'autoplay', 'muted', 'loop', 'height', 'width', 'preload']),
                section: additionalAttributes,
                table: additionalAttributes.concat(['cellpadding']),
                tr: additionalAttributes,
                td: additionalAttributes.concat(['rowspan', 'colspan']),
                th: additionalAttributes.concat(['rowspan', 'colspan']),
                b: additionalAttributes,
            }
        },
        seo: {
            allowedTags: [],
            allowedAttributes: [],
        }
    };

    return sanitizeOptions;
}