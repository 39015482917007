import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { normalizePath } from "../utils/get-url-path";
import gdprAnalytics from '../helper/gdprAnalytics';

const GDPRBanner = () => {
    const [isBannerShown, updateBannerState] = useState(false);

    useEffect(() => {

        const ga = Cookie.get(process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID__GDPR_COOKIE_KEY),
            gtm = Cookie.get(process.env.GATSBY_GOOGLE_TAG_MANAGER_TRACKING_ID__GDPR_COOKIE_KEY),
            fbq = Cookie.get(process.env.GATSBY_FACEBOOK_PIXEL_ID__GDPR_COOKIE_KEY);

        const handleScrollEvent = () => {
            gdprAnalytics();
            window.removeEventListener('scroll', handleScrollEvent);
            window.removeEventListener('touchstart', handleScrollEvent);
            window.removeEventListener('mouseenter', handleScrollEvent);
        };

        function watchScroll() {
            const gdprContainer = document.getElementsByClassName('gdpr__wrapper');
            window.addEventListener('scroll', handleScrollEvent);
            window.addEventListener('touchstart', handleScrollEvent);
            if (gdprContainer.length) {
                [...gdprContainer].forEach((el) => {
                    el.addEventListener('mouseenter', handleScrollEvent);
                });
            }
        }
        if (!ga || !gtm || !fbq) {
            updateBannerState(true);
        }
        watchScroll();
    }, [isBannerShown]);

    const handleAction = (value) => {
        const options = {
            SameSite: 'Lax'
        }

        Cookie.set(process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID__GDPR_COOKIE_KEY, value, options);
        Cookie.set(process.env.GATSBY_GOOGLE_TAG_MANAGER_TRACKING_ID__GDPR_COOKIE_KEY, value, options);
        Cookie.set(process.env.GATSBY_FACEBOOK_PIXEL_ID__GDPR_COOKIE_KEY, value, options);

        updateBannerState(false);
    }

    return isBannerShown && (
        <div className={'gdpr__wrapper'}>
            <div className={'gdpr page-content'}>
                <div className={'gdpr__text'}>
                    We use cookies on our website to make your experience smooth by using analytics & personalization.
                    If you accept it, you'll let us know that you don't mind our collecting information about how you
                    interact with our website. Check out our <AniLink paintDrip duration={1} hex="#ed5d2b"
                                                                      to={normalizePath('terms-of-service')}>Terms of
                    Service</AniLink> to learn more!
                </div>
                <div className={'gdpr__actions'}>
                    <button className={'gdpr__decline'} onClick={() => handleAction(false)}>Decline</button>
                    <button className={'gdpr__accept'} onClick={() => handleAction(true)}>Accept</button>
                </div>
            </div>
        </div>
    );
};

export default GDPRBanner;