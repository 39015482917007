import React, { useEffect, useState } from 'react';

const checkId = (id) => {
    let scriptFbpixelId = document.getElementById(id);
    if (!!scriptFbpixelId) {
        return false;
    }

    switch (id) {
        case 'data-hotjar':
            let hotjar = document.createElement('script');
            hotjar.async = true;
            hotjar.innerHTML = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${process.env.GATSBY_HOTJAR_ID},hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
            hotjar.id = id;
            document.head.appendChild(hotjar);
            break;
        case 'data-fbpixel':
            let fb = document.createElement('script');
            fb.async = true;
            fb.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n, arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init','${process.env.GATSBY_FACEBOOK_PIXEL_ID}');fbq('track', 'PageView');`;
            fb.id = id;
            document.head.appendChild(fb);
            break;
        case 'data-gtm':
            let gtm = document.createElement('script');
            gtm.async = true;
            gtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAG_MANAGER_TRACKING_ID__GDPR_COOKIE_KEY}');`;
            gtm.id = id;
            let gtmNoscript = document.createElement('noscript');
            let gtmNoscriptIframe = document.createElement('iframe');
            gtmNoscriptIframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_TAG_MANAGER_TRACKING_ID__GDPR_COOKIE_KEY}`;
            gtmNoscriptIframe.height = 0;
            gtmNoscriptIframe.width = 0;
            gtmNoscriptIframe.style.display = 'none';
            gtmNoscriptIframe.style.visibility = 'hidden';
            document.head.appendChild(gtm);
            document.head.appendChild(gtmNoscript);
            gtmNoscript.appendChild(gtmNoscriptIframe);
            break;
        case 'data-ga':
            let ga = document.createElement('script');
            ga.async = true;
            ga.innerHTML = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', '${process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID}', 'auto');ga('send', 'pageview')`;
            ga.id = id;
            document.head.appendChild(ga);
            break;
        default:
            break;
    }
}

export default function gdprAnalytics() {
    const fbpixelId = 'data-fbpixel';
    const gtmId = 'data-gtm';
    const gaId = 'data-ga';
    checkId(fbpixelId);
    checkId(gtmId);
    checkId(gaId);
    checkId('data-hotjar');
}

