import React, {useEffect, useState} from "react"
import ReactModal from "react-modal";
import NavMenu from "../template-parts/menu";
import ContactBlock from "../contact-block";
import useDebounce from '../../hooks/useDebounce';

function HeaderMenuExpanded({data, contactUsData}) {
    const {wpMenuSocial, wpMenuMain} = data;
    const [open, setOpen] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);
    const [isSafari, setisSafari] = useState('');
    const debouncedHoverTerm = useDebounce(openTerms, 200);
    const modalStyles = {
        overlay: {
            backgroundColor: 'transparent'
        },
    };
    useEffect(() => {
        const safariBrowser = navigator.userAgent.indexOf("Safari");
        const chromeBrowser = navigator.userAgent.indexOf("Chrome");
        const isSafariClass = (safariBrowser !== -1 && chromeBrowser === -1) ? 'safari-browser' : 'browser';
        setisSafari(isSafariClass);
        debouncedHoverTerm ? setOpen(true) : setOpen(false);
    }, [debouncedHoverTerm]);

    return (
        <>
            <button
                className={'menu-expanded-button'}
                onClick={() => setOpenTerms(!openTerms)}
                aria-label='menu'/>
            <ReactModal
                parentSelector={() => document.querySelector('#header-wrapper')}
                isOpen={open}
                className={'top-menu-modal-content top-menu-modal-overlay'}
                style={modalStyles}
                ariaHideApp={false}
                htmlOpenClassName={`header-popup__open ${isSafari}`}>
                <div
                    className={'scale-up-tl flex-container-nowrap__sb'}>
                    <div className={'main-menu-block'}>
                        <NavMenu data={{wpMenuMain}} menuExpanded={true}/>
                    </div>
                    <div className={'description-block page-content-modal white'}>
                        <ContactBlock contactBlock={contactUsData.contactUsBlock}
                                      hideContactForm={true}/>
                        <nav>
                            <NavMenu data={{wpMenuSocial}} menuExpanded={true}/>
                        </nav>
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

export default HeaderMenuExpanded
