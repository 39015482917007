import React from "react"
import BreadcrumbsItems from "./breadcrumbs-items";

const BreadcrumbsList = ({crumbs, setClass}) => {
    const lastIndex = Array.isArray(crumbs) && crumbs.length - 1;
    return (
        <>
            {Array.isArray(crumbs) && crumbs.length && (
                <ul className={`breadcrumbs ${!!setClass ? setClass : ''}`}>
                    {crumbs.map((el, i) => (
                        <BreadcrumbsItems
                            key={`${el?.pathname}-${i}`}
                            el={el}
                            i={i}
                            lastIndex={lastIndex}
                        />
                    ))}
                </ul>
            )}
        </>
    )
}

export default BreadcrumbsList;
