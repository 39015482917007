export default function getFormFields({ data, fileData }) {
    let form_data = {};
    let dataKeys = Object.keys(data);
    form_data['form_data'] = {};
    form_data['form_data']['skip_spam_check'] = true;
    if (Array.isArray(dataKeys)) {
        dataKeys.forEach(function (el) {
            form_data['form_data'][el] = data[el];
        });
    }
    form_data['form_data']['file'] = !!fileData ? fileData : "without-file";
    return form_data;
};