import React, {useEffect, useState} from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {graphql, useStaticQuery} from "gatsby";
import NavMenu from "./template-parts/menu";
import HeaderMenuExpanded from "../components/header-menu-expanded";
import MageLogo from "../assets/svg/logo.inline.svg";
import "./style.scss";
import * as constants from "../helper/constants";

const Header = () => {
    const data = useStaticQuery(graphql`
        {
            wpMenuMain: wpMenu(slug: {eq: "main-menu"}) {
                name
                menuItems {
                    nodes {
                        label
                        url
                        parentId
                        connectedNode {
                            node {
                                ... on WpContentNode {
                                    uri
                                }
                            }
                        }
                        childItems {
                            nodes {
                                label
                                url
                                parentId
                                connectedNode {
                                node {
                                    ... on WpContentNode {
                                    uri
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            wpMenuSocial: wpMenu(slug: {eq: "social-menu"}) {
                name
                menuItems {
                    nodes {
                        label
                        url
                        parentId
                        connectedNode {
                            node {
                                ... on WpContentNode {
                                    uri
                                }
                            }
                        }
                    }
                }
            }
            
            contactBlock: wpPage(slug: {eq: "contacts"}) {
                contactUsCustomFields {
                    contactUsBlock {
                        text
                    }
                }
            }
        }
    `)

    const [showLogo, setShowLogo] = useState(false);
    const {wpMenuMain, wpMenuSocial, contactBlock} = data;
    const {contactUsCustomFields} = contactBlock;
    const [isSticky, setSticky] = useState(false);
    const [isHeightSticky, setHeightSticky] = useState('auto');
    const [windowSize, setWindowSize] = useState(false);

    useEffect(() => {
        let getWindowSize = window.innerWidth < constants.MOBILE_WIDTH;
        let heightSticky = document.getElementById('header-wrapper').offsetHeight;
        setHeightSticky(heightSticky);
        setWindowSize(getWindowSize);
        setShowLogo(!getWindowSize);

        const handleScrollEvent = () => {
            const position = Math.abs(document.getElementById('___gatsby').getBoundingClientRect().top);
            if (position >= heightSticky) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };
        document.addEventListener('scroll', handleScrollEvent);
    }, []);

    return (
        <header>
            <div id={'header-wrapper'} className={`header-wrapper ${isSticky ? ' sticky' : ''}`}>
                <HeaderMenuExpanded
                    showMenuLink={showLogo}
                    data={{wpMenuSocial, wpMenuMain}}
                    contactUsData={contactUsCustomFields}/>
                <AniLink to="/" paintDrip duration={1} hex="#ed5d2b" className={'top-logo'}>
                    <MageLogo style={{
                        opacity: windowSize ? 1 : showLogo ? 1 : 0,
                        transition: !windowSize ? 'all 1s ease 1s' : ''
                    }}/>
                    <span
                        style={{
                            transform: `translateY(5px)`,
                            display: `inline-block`,
                        }}/>
                </AniLink>
                <nav className="main-menu">
                    <NavMenu data={{wpMenuMain}} className={'main-menu'}/>
                </nav>
            </div>
        </header>
    )
}

export default Header

