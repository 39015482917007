
export default function sendFileData(data, sendData, formId) {
    if (!!data?.file && !!data?.file[0]) {
        const reader = new FileReader();
        let fileData = {
            name: data.file[0].name,
            content: '',
            size: data.file[0].size
        };

        reader.readAsDataURL(data.file[0]);
        reader.onload = () => {
            fileData.content = reader.result;
            sendData({data, fileData, formId});
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    } else {
        sendData({data, formId});
    }
};
