import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";

const BreadcrumbsItems = ({el, i, lastIndex}) => {
    return (
        <li className={`breadcrumbs-item`}>
            {i !== lastIndex
                ? <AniLink to={normalizePath(el.pathname)}
                           paintDrip
                           duration={1}
                           hex="#ed5d2b"
                           className={'breadcrumbs-item__link'}>
                    <span>{el.crumblabel}</span>
                </AniLink>
                : <span className={'breadcrumbs-item__last'}>{el.crumblabel}</span>
            }
        </li>
    )
}

export default BreadcrumbsItems;