import React, {useEffect, useState} from "react";
import Header from "./header";
import Footer from "./footer";
import BreadcrumbsList from "./breadcrumbs";
import sanitizeAddCustomLink from "../helper/sanitizeAddCustomLink";
import protectCopyContent from "../helper/protectCopyContent";
import SeoComponent from "./seo";
import GDPRBanner from "./gdpr";
import MobileMenu from "../components/MobileMenu";
import {useStaticQuery, graphql} from "gatsby";
import {Context} from "./Context.js";
import "./style.scss";

const Layout = ({children, crumbs, setClass, seoData}) => {
    const {props} = children;
    const dataContactBlock = props?.datafld?.dataContactBlock;
    const disablePreFooter = !!(props?.property === 'disablePreFooter');
    const backgroundColor = (!!props?.property && props?.property !== 'disablePreFooter') ? props.property : 'dark-background';

    // Get WP site URL
    const dataSeo = useStaticQuery(graphql`
    {
        commonSeo: wp {
            seo {
                schema {
                    siteUrl
                }
            }
        }
    }`);
    const siteUrl = dataSeo?.commonSeo?.seo?.schema?.siteUrl;
    const [context, setContext] = useState({siteUrl});

    useEffect(() => {
        //To add the skype & tel url for the link in the admin content with sanitizeHtml
        //The link address should be with data-href attribute & class="replace-href" in admin content
        sanitizeAddCustomLink();
        //when we are copying content it is adding a link with the current page.
        document.oncopy = protectCopyContent;

    }, []);

    return (
        <Context.Provider value={[context, setContext]}>
            <SeoComponent seoData={seoData}/>
            <Header/>
            <main
                className={`page-wrapper ${(setClass === 'page-wrapper-wp' || setClass === 'blog-page-wrapper' || setClass === 'post-page-wrapper') ? setClass : ''}`}>
                {!!crumbs && (
                    <BreadcrumbsList
                        crumbs={crumbs}
                        setClass={setClass}/>
                )}
                {children}
            </main>
            <Footer disablePreFooter={disablePreFooter}
                    dataContactBlock={dataContactBlock}
                    backgroundColor={backgroundColor}/>
            <div className={`wrapper__mob-menu-gdpr`}>
                <GDPRBanner/>
                <MobileMenu/>
            </div>
        </Context.Provider>
    )
}

export default Layout
