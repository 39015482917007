import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import NavMenu from "./template-parts/menu"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import MageLogo from "../assets/svg/logo.inline.svg";
import ContactBlock from "./contact-block";

const Footer = ({disablePreFooter, dataContactBlock, backgroundColor}) => {
    const data = useStaticQuery(graphql`
        {
            wpMenuSocial: wpMenu(slug: {eq: "social-menu"}) {
                name
                menuItems {
                    nodes {
                        label
                        url
                        parentId
                        connectedNode {
                            node {
                                ... on WpContentNode {
                                    uri
                                }
                            }
                        }
                    }
                }
            }
            wpMenuFooter: wpMenu(slug: {eq: "footer-menu"}) {
                name
                menuItems {
                    nodes {
                        label
                        url
                        parentId
                        connectedNode {
                            node {
                                ... on WpContentNode {
                                    uri
                                }
                            }
                        }
                    }
                }
            }
            contactBlock: wpPage(slug: {eq: "contacts"}) {
                contactUsCustomFields {
                    contactUsBlock {                  
                        description
                        text
                        titleH2
                        titleH4
                    }
                }
            }
        }
    `)

    const {wpMenuSocial, wpMenuFooter, contactBlock} = data;
    const defaultContactUsData = contactBlock?.contactUsCustomFields?.contactUsBlock;
    const today = new Date();
    const resultContactUsData = !!dataContactBlock ? dataContactBlock : defaultContactUsData;
    const colorClassWrapper = !!resultContactUsData?.classWrapper ? resultContactUsData.classWrapper : '';

    return (
        <footer id={'footer-container'}>
            {!disablePreFooter && (
                <div className={backgroundColor}>
                    <div className={`page-content-modal page-content ${colorClassWrapper}`}>
                        {!!resultContactUsData && (
                            <ContactBlock
                                contactBlock={resultContactUsData}
                                parentBlock={'footer'}/>
                        )}
                    </div>
                </div>
            )}
            <div className={'dark-background'}>
                <div className={'footer'}>
                    <div className={'footer__logo'}>
                        <AniLink to="/" className={'bottom-logo'}>
                            <MageLogo/>
                        </AniLink>
                        <div className={'social-menu'}>
                            <NavMenu data={{wpMenuSocial}}
                                     disableAnimation={true}/>
                        </div>
                    </div>
                    <div className={'footer__copyright'}>
                        <div className={'copyright'}>
                            © Magecom {(today.getFullYear())}. All rights reserved
                        </div>
                        <div className={'footer-menu'}>
                            <NavMenu data={{wpMenuFooter}}
                                     disableAnimation={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer