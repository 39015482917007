import React from "react";

const Preloader = ({status, loader, message}) => {
    return (
        <>
            {loader && (status !== 'mail_sent' || status !== 'spam') ? (
                <div className={'loading-wrapper'}>
                <span className={'loading'}/>
                </div>
                ) :
                (status === 'spam' && <div className={'thank-you-text'}>
                <p className={'h5-title'}>{message}</p>
                </div>)}
        </>
    )
};

export default Preloader;