import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../helper/sanitizeOptions";
import { decode } from 'html-entities';

const SeoComponent = ({ seoData }) => {
    const sanitizeOpt = sanitizeOptions();
    const data = useStaticQuery(graphql`
        {
            homepage: wpPage(isFrontPage: {in: true}) {
                homepageCustomFields {
                    defaultSeoData {
                        title
                        description
                        image {
                            mediaItemUrl
                        }
                        twitterImage{
                            mediaItemUrl
                        }
                        twitterSite
                        twitterCard
                        twitterTitle
                        twitterDescription
                    }
                }
                seo {
                    canonical
                    opengraphUrl
                }
            }

            commonSeo: wp {
                seo {
                    schema {
                        inLanguage
                        siteName
                        siteUrl
                    }
                }
            }
        }`);

    const { defaultSeoData } = data?.homepage?.homepageCustomFields;
    const commonSeo = data?.commonSeo?.seo?.schema;
    const langLocale = commonSeo?.inLanguage?.replace(/-/g, "_");
    const ogUrlData = (!!seoData?.seo?.canonical && seoData?.seo?.canonical === commonSeo?.siteUrl + '/')
        ? commonSeo?.siteUrl
        : commonSeo?.siteUrl + seoData?.seo?.canonical;
    const ogImageData = !!seoData?.postImages
        ? seoData.postImages
        : !!seoData?.additionalMetaTag?.ogImage?.mediaItemUrl
            ? seoData.additionalMetaTag.ogImage.mediaItemUrl
            : defaultSeoData?.image?.mediaItemUrl;

    const twitterImageData = !!seoData?.additionalMetaTag?.twitterImage?.mediaItemUrl
        ? seoData.additionalMetaTag.twitterImage.mediaItemUrl
        : !!seoData?.postImages
            ? seoData.postImages
            : !!seoData?.additionalMetaTag?.ogImage?.mediaItemUrl
                ? seoData.additionalMetaTag.ogImage.mediaItemUrl
                : defaultSeoData?.twitterImage?.mediaItemUrl;

    const twitterDescriptionData = !!seoData?.additionalMetaTag?.twitterDescription
        ? seoData.additionalMetaTag.twitterDescription
        : defaultSeoData?.twitterDescription;
    const prevLink = `${commonSeo?.siteUrl + seoData?.seo?.canonical + (seoData?.pageContext?.page - 1)}`;
    const nextLink = `${commonSeo?.siteUrl + seoData?.seo?.canonical + (seoData?.pageContext?.page + 1)}`;

    const titleDecode = decode(sanitizeHtml(!!seoData?.seo?.title ?
        seoData.seo.title :
        defaultSeoData.title, sanitizeOpt.seo));
    const descriptionDecode = decode(sanitizeHtml(!!seoData?.seo?.metaDesc ? seoData.seo.metaDesc : defaultSeoData.description, sanitizeOpt.seo));
    const ogDescription = !!seoData?.additionalMetaTag?.ogDescription ? seoData.additionalMetaTag.ogDescription : descriptionDecode;
    const ogDescriptionDecode = decode(sanitizeHtml(ogDescription, sanitizeOpt.seo));
    const keywordsDecode = decode(sanitizeHtml(seoData?.additionalMetaTag?.keywords, sanitizeOpt.seo));

    const ogTitleData = !!seoData?.additionalMetaTag?.ogTitle
        ? seoData.additionalMetaTag.ogTitle
        : titleDecode;

    const twitterTitleData = !!seoData?.additionalMetaTag?.twitterTitle
        ? seoData.additionalMetaTag.twitterTitle
        : !!defaultSeoData?.twitterTitle
            ? defaultSeoData.twitterTitle
            : titleDecode;

    return (
        <Helmet
            htmlAttributes={{
                lang: commonSeo?.inLanguage,
            }}
            title={titleDecode}
            meta={[
                {
                    name: `description`,
                    content: descriptionDecode,
                },
                {
                    name: `og:description`,
                    content: ogDescriptionDecode,
                },
                {
                    name: `og:site_name`,
                    content: commonSeo?.siteName,
                },
                {
                    name: `og:locale`,
                    content: langLocale,
                },
                {
                    property: `og:url`,
                    content: ogUrlData,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: ogImageData,
                }
            ].concat(
                !!seoData?.additionalMetaTag?.keywords
                    ? {
                        name: `keywords`,
                        content: keywordsDecode,
                    }
                    : [],
                !!seoData?.additionalMetaTag?.ogType
                    ? {
                        name: `og:type`,
                        content: seoData.additionalMetaTag.ogType,
                    }
                    : [],
                !!ogTitleData
                    ? {
                        name: `og:title`,
                        content: ogTitleData,
                    }
                    : [],
                !!defaultSeoData?.twitterCard
                    ? {
                        name: `twitter:card`,
                        content: defaultSeoData.twitterCard,
                    }
                    : [],
                !!defaultSeoData?.twitterSite
                    ? {
                        name: `twitter:site`,
                        content: defaultSeoData?.twitterSite,
                    }
                    : [],
                !!twitterImageData
                    ? {
                        name: `twitter:image`,
                        content: twitterImageData,
                    }
                    : [],
                !!twitterTitleData
                    ? {
                        name: `twitter:title`,
                        content: twitterTitleData,
                    }
                    : [],
                !!twitterDescriptionData
                    ? {
                        name: `twitter:description`,
                        content: twitterDescriptionData,
                    }
                    : []
            )}
            link={[].concat(
                !!seoData?.pageContext && !!(seoData?.pageContext?.page - 1)
                    ? {
                        rel: `prev`,
                        href: prevLink
                    }
                    : [],
                !!seoData?.pageContext && (seoData?.pageContext?.page + 1 <= seoData?.pageContext?.totalPages)
                    ? {
                        rel: `next`,
                        href: nextLink
                    }
                    : []
            )}
        />
    )
}

export default SeoComponent;