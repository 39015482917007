import React from "react"
import  {MenuItem} from './MenuItem'
const menuLocation = (field, disableAnimation, menuExpanded) => field && (
    <ul>
        {field.menuItems.nodes.map((menuItem, index) => {
            if (menuItem.parentId == null) {
                return (
                    <MenuItem
                        key={`${index}_${menuItem.url}`}
                        menuItem={menuItem}
                        index={index}
                        disableAnimation={disableAnimation}
                        menuExpanded={menuExpanded}
                    />
                )
            }
        })}
    </ul>
)
export default ({absoluteLinks, data, disableAnimation, menuExpanded}) => (data?.wpMenuSocial
    ? menuLocation(data?.wpMenuSocial, disableAnimation, menuExpanded)
    : data?.wpMenuFooter
        ? menuLocation(data?.wpMenuFooter, disableAnimation, menuExpanded)
        : data?.wpMenuMain
            ? menuLocation(data?.wpMenuMain, disableAnimation, menuExpanded)
                : data?.wpMenuMobile
                    ? menuLocation(data?.wpMenuMobile, disableAnimation, menuExpanded)
            : null
)
