import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { contactUsData } from "../../queries";
import { sendEmail } from "../../Mutations";
import { useForm } from "react-hook-form";
import * as constants from "../../helper/constants";
import sendFileData from "../../helper/sendFileData";
import addGoogleCaptchaScript from '../../helper/addGoogleCaptchaLink';
import DefaultForm from "./DefaultForm";
import SpeakerParticipiantForm from "./SpeakerParticipiantForm";
import getFormUrl from "../../helper/getFormUrl";
import queriesForm from "../../helper/queriesForm";
import getFormFields from "../../helper/getFormFields";
import "../style.scss"

const ContactForm = ({ captchaRender, parentBlock, typeForm, urlValue, upcomingEventsState}) => {
    const parentBlockContactForm = !!parentBlock ? parentBlock : '';
    const typeBlockContactForm = !!typeForm ? typeForm : '';
    const [isSpeaker, setIsSpeaker] = useState(false);
    const [formId, setFormId] = useState('');
    const { data } = useQuery(contactUsData, { fetchPolicy: 'no-cache', variables: { id: formId } });
    const [loader, setEventLoader] = useState(false);
    const [urlPath, setUrlPath] = useState(false);
    const [isFile, setIsFile] = useState({
        isType: undefined,
        errorMessage: undefined,
        name: undefined
    });
    const [showCaptcha, setShowCaptcha] = useState(false);
    useEffect(() => {
        setUrlPath(document.location);
        if (captchaRender) {
            addGoogleCaptchaScript();
        }
        const handleScrollEvent = () => {
            addGoogleCaptchaScript();
            window.removeEventListener('scroll', handleScrollEvent);
        };
        function watchScroll() {
            window.addEventListener('scroll', handleScrollEvent);
        }
        watchScroll();
        window.setTimeout(() => {
            setShowCaptcha(true)
        }, 700);

        let formIds = queriesForm(typeForm).idForm;
        if (Array.isArray(formIds)) {
            isSpeaker
                ? setFormId(constants.CONTACT_US_ID_SPEAKER)
                : setFormId(constants.CONTACT_US_ID_PARTICIPIANT);
        } else {
            setFormId(formIds);
        }
    }, [captchaRender, isSpeaker, typeForm, upcomingEventsState]);
    const { register, handleSubmit, errors, formState, reset } = useForm({
        mode: "onSubmit",
    });
    const [
        sendEmailAction,
        { data: emailData }
    ] = useMutation(sendEmail);
    const onSubmit = (data) => {
        sendFileData(data, sendData);
    };
    const sendData = ({data, fileData}) => {
        setEventLoader(true);
        const dataObj = getFormFields({ data, fileData });
        let formId = +data.formId;
        sendEmailAction({
            variables: {
                form_data: JSON.stringify(dataObj.form_data),
                id: formId,
                formId
            }
        }).then(response => {
            reset();
            setEventLoader(!response);
            getFormUrl({urlPath, urlValue, typeForm});
        }).catch(console.error);
    };
    // Include Recaptcha
    let recaptchaInstance;
    const getRecaptchaInstance = (e) => recaptchaInstance = e;
    const executeCaptcha = (e) => {
        e.preventDefault();
        recaptchaInstance.execute();
    };
    const callback = () => {
        console.log('Captcha footer form. Loaded.');
    };
    const makeId = (el) => Math.floor(Math.random() * Math.floor(el));
    const validateFile = (value) => {
        const imageFile = !!value?.length ? value[0] : !!value?.target?.files[0] ? value.target.files[0] : null;
        if (!!imageFile && !imageFile?.name.match(/\.(pdf|docx|png|txt|zip|rar)$/)) {
            setIsFile({
                isType: false,
                errorMessage: 'Please provide correct file extension.',
                name: false
            });
            return false;
        }
        if (!!imageFile && imageFile?.size > 5242880) {
            setIsFile({
                isType: false,
                errorMessage: 'The file size should be 5MB and below.',
                name: false
            });
            return false;
        }
        if (!!imageFile) {
            setIsFile({
                isType: true,
                errorMessage: false,
                name: imageFile.name
            });
        }
        return true;
    };
    const formData = {
        register,
        handleSubmit,
        errors,
        formState,
        reset,
        formId,
        emailData,
        typeBlockContactForm,
        parentBlockContactForm,
        isSpeaker
    };
    return (
        <>
            {data ?
                typeForm === constants.EVENTS_CONTACT_US_CLASS_WRAPPER
                    ? <SpeakerParticipiantForm
                        loader={loader}
                        isFile={isFile}
                        showCaptcha={showCaptcha}
                        formData={formData}
                        validateFile={validateFile}
                        makeId={makeId}
                        callback={callback}
                        executeCaptcha={executeCaptcha}
                        onSubmit={onSubmit}
                        getRecaptchaInstance={getRecaptchaInstance}
                        setIsSpeaker={setIsSpeaker}
                        upcomingEventsState={upcomingEventsState}
                    />
                    : <DefaultForm
                        loader={loader}
                        isFile={isFile}
                        showCaptcha={showCaptcha}
                        formData={formData}
                        validateFile={validateFile}
                        makeId={makeId}
                        callback={callback}
                        executeCaptcha={executeCaptcha}
                        onSubmit={onSubmit}
                        getRecaptchaInstance={getRecaptchaInstance}
                    />
                : null}
        </>
    );
};

export default ContactForm;